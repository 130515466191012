<template>
  <main-template>
    <secondary-pages-background />
    <div class="p-requisites p-other">
      <div class="container-other">
        <div class="wrapper">
          <h1 class="section-title ta--center">
            Реквизиты
          </h1>
          <div class="divider" />
          <p class="mb-l ta--center">
            Для получения дополнительных данных свяжитесь с нами через форму в разделе контакты.
          </p>
          <table class="requisites">
            <tr>
              <td class="title">
                Наименование:
              </td>
              <td class="description">
                ООО "КликТуАйДи"
              </td>
            </tr>
            <tr>
              <td class="title">
                Юридический адрес:
              </td>
              <td class="description">
                117133, г. Москва, ул Академика Варги, дом 8, к. 1, этаж 7, офис 703
              </td>
            </tr>
            <tr>
              <td class="title">
                Почтовый адрес:
              </td>
              <td class="description">
                117133, г. Москва, ул Академика Варги, дом 8, к. 1, этаж 7, офис 703
              </td>
            </tr>
            <tr>
              <td class="title">
                ИНН:
              </td>
              <td class="description">
                7726447754
              </td>
            </tr>
            <tr>
              <td class="title">
                КПП:
              </td>
              <td class="description">
                772601001
              </td>
            </tr>
            <tr>
              <td class="title">
                ОГРН:
              </td>
              <td class="description">
                1197746112552
              </td>
            </tr>
            <tr>
              <td class="title">
                Наименование банка:
              </td>
              <td class="description">
                АО "АЛЬФА-БАНК"
              </td>
            </tr>
            <tr>
              <td class="title">
                БИК:
              </td>
              <td class="description">
                044525593
              </td>
            </tr>
            <tr>
              <td class="title">
                Расчетный счет:
              </td>
              <td class="description">
                40702810902430002331
              </td>
            </tr>
            <tr>
              <td class="title">
                Корреспондентский счет
              </td>
              <td class="description">
                30101810200000000593
              </td>
            </tr>
          </table>
        </div>
      </div>
    </div>
  </main-template>
</template>

<script>
import MainTemplate from '@/layouts/MainTemplate'
import SecondaryPagesBackground from '@/components/SecondaryPagesBackground'

export default {
  name: 'Requisites',
  components: {
    MainTemplate,
    SecondaryPagesBackground
  },
  data() {
    return {
      loading: true
    }
  }
}
</script>

<style lang="scss" scoped>
  .p-requisites {
    .wrapper {
      max-width: 640px;
      .requisites {
        td {
          padding: 10px 0;
        }
        .title {
          text-align: right;
        }
        .description {
          font-weight: 300;
          padding-left: 16px;
          @include max-w-lg {
            padding-left: 12px;
          }
          @include max-w-xs {
            padding-left: 8px;
          }
        }
      }
    }
  }
</style>